

















import Vue from 'vue';
import Article from '@/components/ArticleItem.vue';

export default Vue.extend({
	components: { Article },
	props: {
		block: {
			default: () => {},
			type: Object
		}
	},
	computed: {
		articles () { return this.block.articles || []; }
	}
});
