









































import Vue from 'vue';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default Vue.extend({
	components: { swiper, swiperSlide },
	data () {
		return {
			options: {
				autoHeight: true,
				// height: 460,
				slidesPerView: 1,
				centeredSlides: true,
				navigation: {
					nextEl: '.swiper-btn.next',
					prevEl: '.swiper-btn.prev'
				},
				fadeEffect: {
					crossFade: true
				}
			}
		};
	},
	beforeMount () {
		// fetch main slider slides
		this.$store.dispatch('fetch', {
			storeKey: 'mainSlider',
			fragment: 'slider/0/',
			endpoint: 'module',
			store: true
		});
		// fetch issues
		this.$store.dispatch('fetch', {
			endpoint: 'content',
			storeKey: 'issues',
			fragment: 'issue',
			store: true
		});
		// fetch sub slider blocks
		this.$store.dispatch('fetch', {
			endpoint: 'module',
			storeKey: 'subSliderBlocks',
			fragment: 'sub_slider_block/',
			store: true
		});
	},
	computed: {
		slider () : any { return this.$store.getters.mainSlider; },
		issues () : any { return this.$store.getters.issues; },
		subSliderBlocks () : any { return this.$store.getters.subSliderBlocks; },
		lang () : any { return (this as any).$lang(); }
	}
});
