













import { Component, Vue } from 'vue-property-decorator';

import CategoryBlock from '@/components/CategoryBlock.vue';
import MainSlider from '@/components/MainSlider.vue';

@Component({
	components: { CategoryBlock, MainSlider },
	beforeMount () {
		this.$store.dispatch('fetch', {
			endpoint: 'module',
			fragment: 'block/',
			storeKey: 'mainBlocks',
			store: true
		});
	},
	computed: {
		blocks () { return this.$store.getters.mainBlocks; }
	}
})
export default class Home extends Vue {}
