




























import Vue from 'vue';

export default Vue.extend({
	props: {
		article: {
			default: () => {},
			type: Object
		},
		masonry: {
			default: false,
			type: Boolean
		}
	},
	computed: {
		categories () { return this.article.categories || []; },
		articleURL () : any { return { name: 'article', params: { slug: this.article.slug, lang: (this as any).$lang() } }; },
		intro () {
			const pageBreak = '<!-- pagebreak -->';
			if (this.article.content.indexOf(pageBreak) !== -1) {
				return this.article.content.split(pageBreak)[0];
			} else {
				return this.article.content.split(' ').slice(0, 20).join(' ');
			}
		},
		lang () : any { return (this as any).$lang(); }
	}
});
